// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TaskManager from './TaskManager';
import AddTask from './AddTask';
import Guide from './Guide';
import './App.css';

function App() {
  // Load tasks from localStorage or initialize with an empty array
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    return savedTasks ? JSON.parse(savedTasks) : [];
  });

  // Load theme from localStorage or initialize with 'light'
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || 'light';
  });

  // Save tasks to localStorage whenever tasks state changes
  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  // Save theme to localStorage whenever theme state changes
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Toggle between light and dark themes
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <div className={`app ${theme}`}>
      <Router>
        <Routes>
          <Route 
            path="/" 
            element={<TaskManager tasks={tasks} setTasks={setTasks} theme={theme} toggleTheme={toggleTheme} />} 
          />
          <Route 
            path="/add-task" 
            element={<AddTask tasks={tasks} setTasks={setTasks} theme={theme} toggleTheme={toggleTheme} />} 
          />
          <Route 
            path="/guide" 
            element={<Guide theme={theme} toggleTheme={toggleTheme} />} 
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
