// Guide.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

function Guide({ toggleTheme, theme }) {
  const [formattedDateTime, setFormattedDateTime] = useState('');
  const navigate = useNavigate();

  // Format date and time
  const formatDateTime = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const dateString = date.toLocaleDateString(undefined, options);
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const period = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${dateString} ${hours}:${minutes}:${seconds} ${period}`;
  };

  // Update the formatted date and time every second
  useEffect(() => {
    const updateDateTime = () => setFormattedDateTime(formatDateTime(new Date()));
    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <div className={`card ${theme}`}>
      <div className="card-left">
        <div className="text-container">
          <h2>U</h2>
          <p>Task</p>
        </div>

        <label className="switch">
          <input type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
          <span className="slider" />
        </label>
        <p className="ver">Version 1.1.0</p>
        <p className="datetime">{formattedDateTime}</p>
      </div>

      <div className="card-right">
        <div className="guide-content">
          <h3 className="guide-title">How to Use This Task Manager</h3>
          <ul className="guide-list">
            <li><strong>Adding Tasks:</strong> Use the "+" button to create new tasks.</li>
            <li><strong>Editing Tasks:</strong> Click on a task to view details and edit.</li>
            <li><strong>Deleting Tasks:</strong> Click the delete icon to remove a task.</li>
            <li><strong>Task Alerts:</strong> Tasks with scheduled times will send alerts 1 hour before.</li>
            <li><strong>Changing Task Colors:</strong> Click the colored circle on a task to toggle colors: red for not started, yellow for in progress, and green for completed.</li>
          </ul>
        </div>

        {/* Back Button */}
        <button className="back-button-guide" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
    </div>
  );
}

export default Guide;
