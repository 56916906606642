// AddTask.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './App.css';

function AddTask({ setTasks, tasks, toggleTheme, theme }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('id');

  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskDate, setTaskDate] = useState('');
  const [taskTime, setTaskTime] = useState('');
  const [taskPeriod, setTaskPeriod] = useState('AM');
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // Update current date and time every second
  useEffect(() => {
    const interval = setInterval(() => setCurrentDateTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  // Load task data if editing an existing task
  useEffect(() => {
    if (taskId) {
      const task = tasks[taskId];
      if (task) {
        setTaskTitle(task.title);
        setTaskDescription(task.description);
        setTaskDate(task.date || '');

        const [time, period] = task.time ? task.time.split(' ') : [];
        if (time && period) {
          setTaskTime(time);
          setTaskPeriod(period);
        }
      }
    }
  }, [taskId, tasks]);

  // Handle form submission for adding/updating tasks
  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedTime = `${taskTime} ${taskPeriod}`;

    const newTask = {
      title: taskTitle,
      description: taskDescription,
      date: taskDate,
      time: formattedTime,
      color: '#FF4444', // Default color for new tasks
    };

    if (taskId) {
      setTasks(tasks.map((task, index) => (index === parseInt(taskId) ? { ...task, ...newTask } : task)));
    } else {
      setTasks((prevTasks) => [...prevTasks, newTask]);
    }

    navigate('/');
  };

  // Format current date and time for display
  const formatDateTime = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const dateString = date.toLocaleDateString(undefined, options);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert to 12-hour format
    return `${dateString} ${hours}:${minutes}:${seconds} ${period}`;
  };

  const formattedDateTime = formatDateTime(currentDateTime);

  return (
    <div className={`card ${theme}`}>
      <div className="card-left">
        <div className="text-container">
          <h2>U</h2>
          <p>Task</p>
        </div>

        <label className="switch">
          <input type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
          <span className="slider" />
        </label>
        <p className="ver">Version 1.1.0</p>
        <p className="datetime">{formattedDateTime}</p>
      </div>

      <div className="card-right">
        <form onSubmit={handleSubmit} className="task-form">
          <input
            type="text"
            placeholder="Task Name"
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            required
          />
          <textarea
            placeholder="Task Description"
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
            required
          />

          <div className="date-time-container">
            <input
              type="date"
              value={taskDate}
              onChange={(e) => setTaskDate(e.target.value)}
              required
            />

            <div className="time-selector">
              <input
                type="time"
                value={taskTime}
                onChange={(e) => setTaskTime(e.target.value)}
                required
              />
              <select
                value={taskPeriod}
                onChange={(e) => setTaskPeriod(e.target.value)}
                required
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>

          <button type="submit" className="submit-button">
            {taskId ? 'Update Task' : 'Save Task'}
          </button>
          <button 
            type="button" 
            onClick={() => navigate('/')} 
            className="back-button"
          >
            Back to Task List
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddTask;
