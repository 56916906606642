// TaskManager.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

function TaskManager({ tasks, setTasks, toggleTheme, theme }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [fadeOut, setFadeOut] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessages, setAlertMessages] = useState([]);
  const alertTimeouts = useRef([]);

  // Update the current date and time every second
  useEffect(() => {
    const interval = setInterval(() => setCurrentDateTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  // Display a task alert message
  const showTaskAlert = (task) => {
    setAlertMessages((prev) => [...prev, `Reminder: Your task "${task.title}" is scheduled soon!`]);
    setShowAlert(true);
  };

  // Schedule alerts for tasks 1 hour before their scheduled time
  const scheduleAlerts = useCallback(() => {
    const now = new Date();
    const alerts = tasks
      .filter(task => task.date && task.time)
      .map(task => {
        const taskDateTime = new Date(`${task.date} ${task.time}`);
        const alertTime = new Date(taskDateTime - 60 * 60 * 1000);
        const timeUntilAlert = alertTime - now;

        if (timeUntilAlert > 0) {
          return { task, timeoutId: setTimeout(() => showTaskAlert(task), timeUntilAlert) };
        }
        return null;
      })
      .filter(Boolean);

    return alerts;
  }, [tasks]);

  // Clear all scheduled alerts
  const clearAllAlerts = () => {
    alertTimeouts.current.forEach(({ timeoutId }) => clearTimeout(timeoutId));
    alertTimeouts.current = [];
  };

  // Schedule alerts when the task list changes
  useEffect(() => {
    clearAllAlerts();
    alertTimeouts.current = scheduleAlerts();
  }, [tasks, scheduleAlerts]);

  // Navigation handlers
  const goToAddTask = () => navigate('/add-task');
  const goToGuide = () => navigate('/guide');
  const viewTaskDetail = (taskId) => navigate(`/add-task?id=${taskId}`);

  // Task deletion handlers
  const confirmDeleteTask = (index, event) => {
    event.stopPropagation();
    setTaskToDelete(index);
    setShowModal(true);
  };

  const deleteTask = () => {
    setTasks(tasks.filter((_, i) => i !== taskToDelete));
    setShowModal(false);
    setTaskToDelete(null);
  };

  const handleNoClick = () => {
    setFadeOut(true);
    setTimeout(() => {
      setShowModal(false);
      setFadeOut(false);
    }, 300);
  };

  // Task color change handler
  const changeColor = (index) => {
    setTasks((prev) => {
      const newTasks = [...prev];
      const colors = ['#FF4444', '#00C851', '#FFBB33'];
      const currentColor = newTasks[index].color;
      newTasks[index].color = colors[(colors.indexOf(currentColor) + 1) % colors.length];
      return newTasks;
    });
  };

  // Date and time formatting
  const formatDateTime = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const dateString = date.toLocaleDateString(undefined, options);
    const hours = date.getHours();
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    return `${dateString} ${formattedHours}:${minutes}:${seconds} ${period}`;
  };

  const formattedDateTime = formatDateTime(currentDateTime);

  // Alert box handler
  const handleOkClick = () => {
    setShowAlert(false);
    setAlertMessages([]);
  };

  return (
    <div className={`card ${theme}`}>
      <div className="card-left">
        <div className="text-container">
          <h2>U</h2>
          <p>Task</p>
        </div>
        <label className="switch">
          <input type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
          <span className="slider" />
        </label>
        <p className="ver">Version 1.1.0</p>
        <p className="datetime">{formattedDateTime}</p>
      </div>

      <div className="card-right">
        <div className="task-list">
          {tasks.length ? (
            tasks.map((task, index) => (
              <div key={index} className="task-card" onClick={() => viewTaskDetail(index)}>
                <div
                  className="color-circle"
                  onClick={(e) => { e.stopPropagation(); changeColor(index); }}
                  style={{ backgroundColor: task.color }}
                />
                <h3>{task.title}</h3>
                <button className="delete-button" onClick={(e) => confirmDeleteTask(index, e)}>
                  <span className="material-symbols-outlined">delete</span>
                </button>
              </div>
            ))
          ) : (
            <p className="no-tasks-message">No tasks available</p>
          )}
        </div>
      </div>

      {/* Add Task Button */}
      <button className="add-task-button" onClick={goToAddTask}>+</button>

      {/* Guide Button */}
      <button className="guide-button" onClick={goToGuide}>
        <span className="material-symbols-outlined guide-icon">book</span>
      </button>

      {/* Confirmation Modal */}
      {showModal && (
        <div className={`modal ${fadeOut ? 'fade-out' : ''}`}>
          <div className="modal-content">
            <h3>Are you sure you want to delete this task?</h3>
            <div className="modal-actions">
              <button className="yes-button" onClick={deleteTask}>Yes</button>
              <button className="no-button" onClick={handleNoClick}>No</button>
            </div>
          </div>
        </div>
      )}

      {/* Alert Box */}
      {showAlert && (
        <div className="alert-box">
          <div className="alert-content">
            {alertMessages.map((message, index) => <p key={index}>{message}</p>)}
            <button className="ok-button" onClick={handleOkClick}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskManager;
